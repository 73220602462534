import React from 'react'
import SectionTitle from '../SectionTitle'
import { PACKAGES } from '../../../data';
import PackageCard from '../PackageCard';

const Packages = () => {
    const { title, exports, projects } = PACKAGES;
    return (
        <>

            <div className="container">

                {/* Package section */}
                <div className="">
                    {/* ==== Package Content ==== */}
                    <div className="">
                        {/* ==== Section Title ==== */}
                        <SectionTitle
                            center
                            title={title}
                            subtitle={`Expert in`}
                            typeWriter={exports}
                        // className="md:text-left text-center"
                        />
                    </div>
                    <div className="text-center pb-[40px]">
                        <p className="text-[18px] font-semibold text-gray-600 "> <span className=' text-[21px] font-semibold text-primary-color '>N/B: </span> For more information about our packages, including price and to know more details, please contact us via inbox. </p>
                        <p className="text-[18px] font-semibold text-gray-600 "> This Package pricing is just an example. We have many more services and packages for you.</p>

                    </div>
                </div>

                <div className="flex flex-wrap justify-center gap-5  mx-auto">
                    {projects.map((project, index) => (
                        <PackageCard
                            key={index}
                            {...project}
                            project={project}

                        />
                    ))}
                </div>


                <div className="w-fit mx-auto mt-10 pb-4">
                    <a href="https://wa.me/8801715362067" rel="noreferrer" target='_blank' className="px-6 py-2 text-white font-semibold rounded-[5px] cursor-pointer bg-primary-color border border-primary-color hover:text-primary-color
                              hover:bg-white  transition-all duration-300 ease-in-out ">
                        Order Now
                    </a>
                </div>

            </div>


        </>
    )
}

export default Packages;