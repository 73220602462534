import React, { useEffect } from 'react'
import { Contact, Hero, } from "../components/sections";
import Packages from '../components/elements/Packages';
import FAQ from '../components/elements/Faq';


function PackagesPage() {

    useEffect(() => {
        document.title = "RH IT - Packages"
    })
    return (
        <>
            <Hero />

            <Packages />
            <FAQ />

            <Contact />
        </>
    )
}

export default PackagesPage;