import React, { useEffect } from 'react'
import { Contact, Hero, } from "../components/sections";
import AboutDec from '../components/elements/AboutDec';


function AboutPage() {

    useEffect(() => {
        document.title = "RH IT - About"
    })
    return (
        <>
            <Hero />

            <AboutDec />
            <Contact />
        </>
    )
}

export default AboutPage;