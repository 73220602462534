
import Faq from "react-faq-component";

const data = {
    title: "FAQ:",
    rows: [
        {
            title: "Are there any discounts for large orders?",
            content: `Yes! We will give a special discount on large orders.`,
        },
        {
            title: "Can you deliver extra fast?",
            content: "Yes! But, We will charge more for extra fast delivery.",
        },
        {
            title: "Can you provide a sample before ordering?",
            content: `Yes, of course. We can request a 10-15-minute work sample before ordering.`,
        },
        {
            title: "Do you have a team to handle the workload?",
            content: "Yes, We have a team and can manage your workload.",
        },
        {
            title: "I have found a better offer than yours. Can you change your price(Offer)?",
            content: "Yes, If you need to change my offer please contact us via inbox.",
        },
        {
            title: "I'm not sure how many leads I may need, then what can I do?",
            content: "It's very easy! you can start with the basic package that will help us to determine the number of hours your project will take. if you have any questions, you can contact us via inbox and We will reply to you as soon as possible. Thank You",
        },
        {
            title: "What other experience do you have?",
            content: "We have 4 Years of experience in this sector. We can do all types of Data Entry, Copy-Paste, Web Research, Web Scraping, Virtual Assistant, Lead Generation, PDF conversion, etc. work based on your Instructions. We also provide all kinds of IT services.",
        },
    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: '#543fff',
    titleTextSize: '40px',
    rowTitleColor: '#333',
    rowTitleTextSize: '24px',
    // rowContentColor: 'grey',
    rowContentTextSize: '20px',
    rowContentPaddingTop: '10px',
    rowContentPaddingBottom: '30px',
    rowContentPaddingLeft: '20px',
    rowContentPaddingRight: '50px',
    // arrowColor: "red",
    //transitionDuration: "1s",
    // timingFunc: "ease"
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

export default function App() {

    return (
        <div className="container">
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    );
}