import { Routes, Route, } from "react-router-dom";
import RouterWrapper from "./RouterWrapper";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import ServicesPage from "./ServicesPage";
import ContactPage from "./ContactPage";
import NotFound from "./NotFound";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import PackagesPage from "./PackagesPage";


function Pages() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    return (
        <>
            <Routes>

                <Route path="/" element={<RouterWrapper />} >
                    <Route index element={<HomePage />} />
                    <Route path="about" element={<AboutPage />} />
                    <Route path="services" element={<ServicesPage />} />
                    <Route path="packages" element={<PackagesPage />} />
                    <Route path="contact" element={<ContactPage />} />
                </Route>

                <Route path="*" element={<NotFound />} />

            </Routes>
        </>
    )
}

export default Pages