

export const PACKAGES = {
    title: "Our Package's",
    exports: ['Digital Service', 'IT Service',],
    projects: [
        {
            title: 'B2b Leads Package',
            price: 'Price: 100$',

            subservice: [
                '1000 Valid Leads',
                'First & Last Name',
                'Title (Designation) ',
                'E-mail Address (Tested & Verified)',
                'Location/Address',
                'LinkedIn Profile (If Available)',
                'Company Website',
                'Company Phone number',
                'Company Email Address',
                'Company Headcount',
                'Industry Type',
                'It will be Changed for Clients Needs.',

            ],
        },

        {
            title: 'Email List Package',
            price: 'Price: 100$',

            subservice: [
                '1000 Verified Email',
                '100% Valid Email',
                'Targeted Person Email',
                'Any Source to Mail Collect',
                'Mail Collect by Many Tools',
                'Business or Personal Mail',
                'Email Related Any Task.',
            ],
        },
        {
            title: 'Direct Mobile Number Package',
            price: 'Price: 100$',

            subservice: [
                '1000 Targeted Number',
                '100% Valid Phone number',
                '100% Verified Phone Number',
                'Targeted Person Number',
                'Personal Phone Number',
                'Personal Mobile Number',
                'Direct Phone Number',
                '100% Linkedin Person Number',
                'Landline or Direct Number',
                'Collected From Lusha & Other Sources.',

            ],
        },
        {
            title: 'Web Research Package',
            price: 'Price: 50$',

            subservice: [
                '5 Hours of Web Research',
                'Expert Research',
                'Data Collection',
                'Data Analysis',
                'Organizing and Synthesizing',
                'Documentation and Reporting',
                'It will be Changed for Clients Needs.',
            ],
        },
        {
            title: 'Data Entry Package',
            price: 'Price: 50$',

            subservice: [
                '5 Hours Data Entry',
                'Copy & Paste',
                'Typing',
                'Data Collection',
                'PDF Conversion',
                'CMS Data Entry',
                'WordPress Data Entry',
                'Any Kind of Data Entry',
                'It will be Changed for Clients Needs.',
            ],
        },
        {
            title: 'Product Upload & Listing Package',
            price: 'Price: 100$',

            subservice: [
                '1000 Product Upload & Listing',
                'Product Data Add',
                'Description Add',
                'Color, size & other Listing',
                'Photo Add or Change',
                'Any Data Upload or Change',
                'Excel to CMS Data Upload',
                'Daily Data Update',
                'It will be Changed for Clients Needs.',
            ],
        },
        {
            title: 'B2C Leads Package',
            price: 'Price: 100$',

            subservice: [
                '1K Leads',
                'First & Last Name',
                'Title (Designation) ',
                'E-mail Address (Tested & Verified)',
                'Location/Address',
                'LinkedIn Profile (If Available)',
                'Social Media Link (If Available)',
                'Country Name',
                'Gender',
                'Age',
                'It will be Changed for Clients Needs.',
            ],
        },
        {
            title: 'Web Scraping Package',
            price: 'Price: 100$',

            subservice: [
                '100 Page Scraping',
                'No Data Missing',
                'Any Source to Scraping',
                '100% Fresh Data',
                'Business Data Scraping',
                'Excel File',
                'CSV File',
                'It will be Changed for Clients Needs.',
            ],
        },
        {
            title: 'VA Support Package',
            price: 'Price: 50$',

            subservice: [
                '5 Hours Support',
                'Any Assistance',
                'Personal Work Management',
                'Personal Data Management',
                'Expert Assistance',
                'Data Entry Support',
                '100% Secure Support',
                'It will be Changed for Clients Needs.',
            ],
        },


    ],

}