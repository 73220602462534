import PackageSingle from "../PackageSingle";
import "./style.css";
import { IoCheckmarkSharp } from "react-icons/io5";


function PackageCard({ title, price, subservice }) {
  return (
    <div
      className="w-full sm:w-[48%] md:w-[31%] shadow-md  rounded-md  border border-primary-color "

    >
      <div className="w-full flex flex-col justify-end gap-2 md:gap-3">

        <div className=" md:p-2 px-3 py-2 mb-4">
          <p className="text-gray-600 text-center   text-[22px] font-semibold pb-4">{title}</p>
          <p className="text-primary-color text-center text-[20px] font-semibold pb-6">{price}</p>
          <div>
            {subservice?.map((singlesubservice, index) => (
              <div key={index} className="p-[2px]">
                <PackageSingle
                  icon={<IoCheckmarkSharp size={22} />}
                  text={singlesubservice}
                />
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
}

export default PackageCard;
