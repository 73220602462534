import React, { useEffect } from 'react'
import { Contact } from '../components/sections'
import ContactDet from '../components/sections/ContactDet'

function ContactPage() {
    useEffect(() => {
        document.title = "RH IT - Contact"
    })
    return (
        <div>

            <ContactDet />
            <Contact />

        </div>
    )
}

export default ContactPage